.team {
    padding-bottom: 120px;
}

.container {
    /* max-width: 1280px; */
    padding: 0 4rem;
    margin: 0 auto;
}

.title {
    font-family: 'Coalition', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 2.5rem;
    line-height: 3.5rem;
    text-transform: uppercase;
    text-align: center;
    color: #ffffff;
}

.subtitle {
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.75rem;
    text-transform: uppercase;
    text-align: center;
    color: #ffffff;
    margin-bottom: 2rem;
}

.blockTeam {
    width: 100%;
    row-gap: 48px;
}

.teammate {
    width: 100%;
    text-align: center;
}

.blockImg {
    background-color: rgba(48, 49, 56, 1);
    width: 100%;
    /* min-height: 290px; */
    border: none;
    border-radius: 1rem;
    display: grid;
    align-items: flex-end;
    position: relative;
}

.img {
    border-radius: 1rem;
    overflow: hidden;
}

.float {
    position: absolute;
    width: 41px;
    height: 41px;
    bottom: -1.125rem;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity .3s ease-in;
}

.float:hover {
    opacity: .8;
}

.name {
    margin-top: 1.75rem;
    margin-bottom: 0.25rem;
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: #ffffff;
}

.profession {
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #ffffff;
    margin-bottom: 0.25rem;
}

.about {
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    color: rgba(255, 255, 255, 0.6);
}

@media (min-width: 1920px) {
    .container {
        max-width: 80%;
    }
  }

@media (min-width: 1440px) and (max-width: 1920px) {
    .container {
        max-width: 90%;
    }
}

@media only screen and (max-width: 768px) {
    .team {
        padding-bottom: 100px;
    }

    .name {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    .profession {
        margin-bottom: 0.5rem;
    }

    .container {
        padding: 0 1rem;
    }

    .title {
        font-size: 1.5rem;
        line-height: 2.25rem;
        margin-bottom: 2.25rem;
    }

    .blockTeam {
        row-gap: 1.25rem;
    }

    .blockImg {
        min-height: auto;
    }

    .float {
        width: 1.875rem;
        height: 1.875rem;
    }
}
